import {Navigate, useLocation} from "react-router-dom";
import GuestGuard from "guards/GuestGuard";
import AuthGuard from "guards/AuthGuard";
import DashboardLayout from "layouts/dashboard";
import LogoOnlyLayout from "layouts/LogoOnlyLayout";
import {lazy, Suspense} from "react";
import LoadingScreen from "components/LoadingScreen";

const Loadable = (Component) => (props) => {
    // eslint-disable-next-line
    const {pathname} = useLocation();

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes("/")}/>}>
            <Component {...props} />
        </Suspense>
    );
};

//
// AUTHENTICATION
const Login = Loadable(lazy(() => import("pages/auth/Login")));
// const Register = Loadable(lazy(() => import('pages/auth/Register')));
// const ResetPassword = Loadable(lazy(() => import('pages/auth/ResetPassword')));
// const VerifyCode = Loadable(lazy(() => import('pages/auth/VerifyCode')));

// // GENERAL
const GeneralApp = Loadable(lazy(() => import("pages/dashboard/GeneralApp")));

// Store
// // Main Category
const SubMenuList = Loadable(
    lazy(() => import("../pages/store/products/subMenu/subMenuList"))
);
const SubMenuCreate = Loadable(
    lazy(() => import("../pages/store/products/subMenu/subMenuCreate"))
);
// // Category
const StoresCategoryList = Loadable(
    lazy(() => import("pages/store/products/category/categoriesList"))
);
const CategoryCreate = Loadable(
    lazy(() => import("pages/store/products/category/categoryCreate"))
);
// // Menus
const MenusList = Loadable(
    lazy(() => import("pages/store/products/menus/menusList"))
);
const MenusCreate = Loadable(
    lazy(() => import("pages/store/products/menus/menusCreate"))
);
// // Products
const StoreProducts = Loadable(
    lazy(() => import("pages/store/products/productsList/productsList"))
);

const StoreProductsCreate = Loadable(
    lazy(() => import("pages/store/products/productsList/productCreate"))
);
const StoreProductsAttr = Loadable(
    lazy(() => import("pages/store/products/productDetail/productsDetail"))
);
const ProductsAttrCreate = Loadable(
    lazy(() => import("pages/store/products/productDetail/productDetailCreate"))
);

const LandingList = Loadable(
    lazy(() => import("../pages/store/landing/landingList"))
);
const LandingCreate = Loadable(
    lazy(() => import("../pages/store/landing/landingCreate"))
);
const ProcessList = Loadable(
    lazy(() => import("../pages/store/processes/processList"))
);
const StepsList = Loadable(
    lazy(() => import("../pages/store/processes/steps/stepsList"))
);
const ProcessCreate = Loadable(
    lazy(() => import("../pages/store/processes/processCreate"))
);

// // Roles
const RolesList = Loadable(lazy(() => import("pages/store/roles/rolesList")));
const RolesCreate = Loadable(
    lazy(() => import("pages/store/roles/rolesCreate"))
);
// // PageInfo
const PageInfoList = Loadable(
    lazy(() => import("pages/store/pageInfo/pageInfoList"))
);
const PageInfoCreate = Loadable(
    lazy(() => import("pages/store/pageInfo/pageInfoCreate"))
);
// // Permission
const PermissionList = Loadable(
    lazy(() => import("pages/store/permission/permissionList"))
);

const PermissionCreate = Loadable(
    lazy(() => import("pages/store/permission/permissionCreate"))
);
// // group
const GroupList = Loadable(lazy(() => import("pages/store/groups/groupList")));

const GroupCreate = Loadable(
    lazy(() => import("pages/store/groups/groupCreate"))
);
// // Profiles
const ProfilesList = Loadable(
    lazy(() => import("pages/store/profiles/profilesList"))
);

const ProfilesCreate = Loadable(
    lazy(() => import("pages/store/profiles/profilesCreate"))
);
// // Crm
const CrmList = Loadable(lazy(() => import("pages/store/crm/crmList")));

const CrmCreate = Loadable(lazy(() => import("pages/store/crm/crmCreate")));
// // CrmContacts
const CrmContactsList = Loadable(
    lazy(() => import("pages/store/crmContacts/crmContactsList"))
);

const CrmContactsCreate = Loadable(
    lazy(() => import("pages/store/crmContacts/crmContactsCreate"))
);
// // modules
const ModulesList = Loadable(
    lazy(() => import("pages/store/modules/modulesList"))
);

const ModulesCreate = Loadable(
    lazy(() => import("pages/store/modules/modulesCreate"))
);
// // users
const UserList = Loadable(lazy(() => import("pages/store/users/usersList")));
const UserCreate = Loadable(
    lazy(() => import("pages/store/users/usersCreate"))
);
const UserCreated = Loadable(
    lazy(() => import("pages/store/users/userCreated"))
);
const UserStatus = Loadable(
    lazy(() => import("pages/store/users/userDocStatus"))
);
const UserInfoAttributes = Loadable(
    lazy(() => import("pages/store/users/userInfoAttributes"))
);
// // userAttributes
const UserAttributesList = Loadable(
    lazy(() => import("pages/store/userAttributes/userAttributesList"))
);
const UserAttributesCreate = Loadable(
    lazy(() => import("pages/store/userAttributes/userAttributesCreate"))
);
// // license
const LicenseList = Loadable(
    lazy(() => import("pages/store/license/licenseList/licenseList"))
);
const LicenseCreate = Loadable(
    lazy(() => import("pages/store/license/licenseList/licenseCreate"))
);
const ProductWithLicenseList = Loadable(
    lazy(() =>
        import("../pages/store/license/productWithLicense/productWithLicenseList")
    )
);
const ProductWithLicenseCreate = Loadable(
    lazy(() =>
        import("../pages/store/license/productWithLicense/productWithLicenseCreate")
    )
);
// // consult
const ConsultList = Loadable(
    lazy(() => import("../pages/store/consult/consultList"))
);
// // comments
const CommentsList = Loadable(
    lazy(() => import("../pages/store/comments/commentsList"))
);
const CommentsCreate = Loadable(
    lazy(() => import("pages/store/comments/commentsCreate"))
);
// // hiring
const HiringList = Loadable(
    lazy(() => import("../pages/store/hiring/hiringList"))
);
const HiringCreate = Loadable(
    lazy(() => import("pages/store/hiring/hiringCreate"))
);
// // transactions
const TransactionList = Loadable(
    lazy(() => import("../pages/store/transactions/transactionsList"))
);
// // referrals
const ReferralsList = Loadable(
    lazy(() => import("../pages/store/referrals/referralsList"))
);
// // campaign
const CampaignList = Loadable(
    lazy(() => import("../pages/store/campaign/campaignList"))
);
// // cart
const CartList = Loadable(lazy(() => import("../pages/store/cart/cartList")));
// // userDoc
const UserDocList = Loadable(
    lazy(() => import("../pages/store/userDoc/userDocList"))
);
const UserDocListCreate = Loadable(
    lazy(() => import("../pages/store/userDoc/userDocCreate"))
);
// // faq
const FAQList = Loadable(
    lazy(() => import("../pages/store/products/faq/faqList/faqList"))
);
const FAQListCreate = Loadable(
    lazy(() => import("../pages/store/products/faq/faqList/faqCreate"))
);
const FAQCategoryList = Loadable(
    lazy(() => import("../pages/store/products/faq/faqCategory/faqCategoryList"))
);
const FAQCategoryCreate = Loadable(
    lazy(() =>
        import("../pages/store/products/faq/faqCategory/faqCategoryCreate")
    )
);
// // faqProfile
const FAQProfileList = Loadable(
    lazy(() => import("../pages/store/faqProfile/faqList/faqList"))
);
const FAQProfileListCreate = Loadable(
    lazy(() => import("../pages/store/faqProfile/faqList/faqCreate"))
);
const FAQProfileCategoryList = Loadable(
    lazy(() => import("../pages/store/faqProfile/faqCategory/faqCategoryList"))
);
const FAQProfileCategoryCreate = Loadable(
    lazy(() => import("../pages/store/faqProfile/faqCategory/faqCategoryCreate"))
);
//questionSurvey
const QuestionSurveyList = Loadable(
    lazy(() =>
        import("../pages/store/products/questionSurvey/questionSurveyList")
    )
);
const QuestionSurveyListCreate = Loadable(
    lazy(() =>
        import("../pages/store/products/questionSurvey/questionSurveyCreate")
    )
);
//categoryUpload
const CategoryUploadList = Loadable(
    lazy(() => import("../pages/store/categoryUpload/categoryUploadList"))
);
const CategoryUploadListCreate = Loadable(
    lazy(() => import("../pages/store/categoryUpload/categoryUploadCreate"))
);
//Upload
const UploadList = Loadable(
    lazy(() => import("../pages/store/upload/uploadList"))
);
const UploadListCreate = Loadable(
    lazy(() => import("../pages/store/upload/uploadCreate"))
);
//topic
const TopicListCreate = Loadable(
    lazy(() => import("../pages/store/topic/topicCreate"))
);
//tickets
const TicketsList = Loadable(
    lazy(() => import("../pages/store/tickets/ticketsList/tickestList"))
);
const TicketAnswerCreate = Loadable(
    lazy(() => import("../pages/store/tickets/ticketsList/ticketAnswerCreate"))
);
//lms
const LmsList = Loadable(lazy(() => import("../pages/store/lms/lmsList")));
const LmsListCreate = Loadable(
    lazy(() => import("../pages/store/lms/lmsCreate"))
);
const QusListCreate = Loadable(
    lazy(() => import("../pages/store/lms/QuestionCreate"))
);
const LicenselmsList = Loadable(
    lazy(() => import("../pages/store/lms/license/licenseList"))
);
const LicenselmsCreate = Loadable(
    lazy(() => import("../pages/store/lms/license/licenseCreate"))
);
//IELTS
const IELTSList = Loadable(
    lazy(() => import("../pages/store/IELTS/IELTSList"))
);
const IELTSListCreate = Loadable(
    lazy(() => import("../pages/store/IELTS/IELTSCreate"))
);
//blog
const BlogList = Loadable(
    lazy(() => import("../pages/dashboard/blog/blogsList/blogList"))
);
const BlogListCreate = Loadable(
    lazy(() => import("../pages/dashboard/blog/blogsList/blogCreate"))
);
//BlogCategory
const BlogCategoryList = Loadable(
    lazy(() => import("../pages/dashboard/blog/blogsCategory/blogCategoryList"))
);
const BlogCategoryCreate = Loadable(
    lazy(() => import("../pages/dashboard/blog/blogsCategory/blogCategoryCreate"))
);
//BlogTag
const BlogTagList = Loadable(
    lazy(() => import("../pages/dashboard/blog/blogsTag/blogTagList"))
);
const BlogTagCreate = Loadable(
    lazy(() => import("../pages/dashboard/blog/blogsTag/blogTagCreate"))
);
//news
const NewsList = Loadable(
    lazy(() => import("../pages/store/news/newsList/newsList"))
);
const NewsListCreate = Loadable(
    lazy(() => import("../pages/store/news/newsList/newsCreate"))
);
//NewsCategory
const NewsCategoryList = Loadable(
    lazy(() => import("../pages/store/news/newsCategory/newsCategoryList"))
);
const NewsCategoryCreate = Loadable(
    lazy(() => import("../pages/store/news/newsCategory/newsCategoryCreate"))
);
//NewsTag
const NewsTagList = Loadable(
    lazy(() => import("../pages/store/news/newsTag/newsTagList"))
);
const NewsTagCreate = Loadable(
    lazy(() => import("../pages/store/news/newsTag/newsTagCreate"))
);
//TicketCategory
const TicketCategoryList = Loadable(
    lazy(() => import("../pages/store/ticketCategory/ticketCategoryList"))
);
const TicketCategoryListCreate = Loadable(
    lazy(() => import("../pages/store/ticketCategory/ticketCategoryCreate"))
);
//media
const MediaList = Loadable(
    lazy(() => import("../pages/store/media/mediaList"))
);
const MediaCreate = Loadable(
    lazy(() => import("../pages/store/media/mediaCreate"))
);
const LogsList = Loadable(
    lazy(() => import("pages/store/report/reportsList/reportList"))
);
const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));

// Create route mapping
export const routeComponentMapping = {
    "/auth/login": Login,
    "/dashboard/app": GeneralApp,

    // Store routes
    "/dashboard/products/subMenu": SubMenuList,
    "/dashboard/products/subMenu/create": SubMenuCreate,
    "/dashboard/products/category": StoresCategoryList,
    "/dashboard/products/category/create": CategoryCreate,
    "/dashboard/products/menus": MenusList,
    "/dashboard/products/menus/create": MenusCreate,
    "/dashboard/products/list": StoreProducts,
    "/dashboard/products/list/create": StoreProductsCreate,
    "/dashboard/products/detail": StoreProductsAttr,
    "/dashboard/products/detail/create": ProductsAttrCreate,
    "/dashboard/landing": LandingList,
    "/dashboard/landing/create": LandingCreate,
    "/dashboard/process": ProcessList,
    "/dashboard/process/create": ProcessCreate,
    "/dashboard/process/steps": StepsList,

    // Roles
    "/dashboard/roles": RolesList,
    "/dashboard/roles/create": RolesCreate,
    // PageInfo
    "/dashboard/pageInfo": PageInfoList,
    "/dashboard/pageInfo/create": PageInfoCreate,
    // Permission
    "/dashboard/permission": PermissionList,
    "/dashboard/permission/create": PermissionCreate,
    // Groups
    "/dashboard/groups": GroupList,
    "/dashboard/groups/create": GroupCreate,
    // Profiles
    "/dashboard/profiles": ProfilesList,
    "/dashboard/profiles/create": ProfilesCreate,
    // CRM
    "/dashboard/crm": CrmList,
    "/dashboard/crm/create": CrmCreate,
    // CRM Contacts
    "/dashboard/crmContacts": CrmContactsList,
    "/dashboard/crmContacts/create": CrmContactsCreate,
    // Users
    "/dashboard/users": UserList,
    "/dashboard/users/create": UserCreate,
    "/dashboard/users/created": UserCreated,
    "/dashboard/users/:id/edit": UserCreate, // Dynamic route
    "/dashboard/users/:id/status": UserStatus,
    "/dashboard/users/:id/attributes": UserInfoAttributes,
    // User Attributes
    "/dashboard/userAttributes": UserAttributesList,
    "/dashboard/userAttributes/create": UserAttributesCreate,
    // License
    "/dashboard/license": LicenseList,
    "/dashboard/license/create": LicenseCreate,
    // Product with License
    "/dashboard/productsWithLicense": ProductWithLicenseList,
    "/dashboard/productsWithLicense/create": ProductWithLicenseCreate,
    // Consult
    "/dashboard/consult": ConsultList,
    // Comments
    "/dashboard/comments": CommentsList,
    "/dashboard/comments/create": CommentsCreate,
    // Hiring
    "/dashboard/hiring": HiringList,
    "/dashboard/hiring/create": HiringCreate,
    // Transactions
    "/dashboard/transaction": TransactionList,
    // Referrals
    "/dashboard/referrals": ReferralsList,
    // Campaign
    "/dashboard/campaign": CampaignList,
    // Cart
    "/dashboard/cart": CartList,
    // User Document
    "/dashboard/userDoc": UserDocList,
    "/dashboard/userDoc/create": UserDocListCreate,
    // FAQ
    "/dashboard/faq": FAQList,
    "/dashboard/faq/create": FAQListCreate,
    // FAQ Category
    "/dashboard/faq/category": FAQCategoryList,
    "/dashboard/faq/category/create": FAQCategoryCreate,
    // FAQ Profile
    "/dashboard/faqProfile": FAQProfileList,
    "/dashboard/faqProfile/create": FAQProfileListCreate,
    // Question Survey
    "/dashboard/questionSurvey": QuestionSurveyList,
    "/dashboard/questionSurvey/create": QuestionSurveyListCreate,
    // Category Upload
    "/dashboard/categoryUpload": CategoryUploadList,
    "/dashboard/categoryUpload/create": CategoryUploadListCreate,
    // Upload
    "/dashboard/upload": UploadList,
    "/dashboard/upload/create": UploadListCreate,
    // Topic
    "/dashboard/topic": TopicListCreate,
    // Tickets
    "/dashboard/tickets": TicketsList,
    "/dashboard/tickets/create": TicketAnswerCreate,
    // LMS
    "/dashboard/lms": LmsList,
    "/dashboard/lms/create": LmsListCreate,
    // IELTS
    "/dashboard/IELTS": IELTSList,
    "/dashboard/IELTS/create": IELTSListCreate,
    // Blog
    "/dashboard/blog": BlogList,
    "/dashboard/blog/create": BlogListCreate,
    // Blog Category
    "/dashboard/blog/category": BlogCategoryList,
    "/dashboard/blog/category/create": BlogCategoryCreate,
    // Blog Tag
    "/dashboard/blog/tags": BlogTagList,
    "/dashboard/blog/tags/create": BlogTagCreate,
    // News
    "/dashboard/news": NewsList,
    "/dashboard/news/create": NewsListCreate,
    // News Category
    "/dashboard/news/category": NewsCategoryList,
    "/dashboard/news/category/create": NewsCategoryCreate,
    // News Tag
    "/dashboard/news/tag": NewsTagList,
    "/dashboard/news/tag/create": NewsTagCreate,
    // Ticket Category
    "/dashboard/ticketCategory": TicketCategoryList,
    "/dashboard/ticketCategory/create": TicketCategoryListCreate,
    // Media
    "/dashboard/media": MediaList,
    "/dashboard/media/create": MediaCreate,
    // Logs
    "/dashboard/reports/logs": LogsList,
    // Error Pages
    "/404": NotFound,
    "/500": Page500,
};
// static routes
/*const dynamicRoutes =  [
    {path: "app", element: <GeneralApp/>},
    {
        path: "blog",
        children: [
            {
                element: <Navigate to="/dashboard/blog/list" replace/>,
                index: true,
            },
            {path: "list", element: <BlogList/>},
            {path: "list/create", element: <BlogListCreate/>},
            {path: "list/:id/edit", element: <BlogListCreate/>},

            {path: "category", element: <BlogCategoryList/>},
            {path: "category/create", element: <BlogCategoryCreate/>},
            {path: "category/:id/edit", element: <BlogCategoryCreate/>},

            {path: "tags", element: <BlogTagList/>},
            {path: "tags/create", element: <BlogTagCreate/>},
            {path: "tags/:id/edit", element: <BlogTagCreate/>},
        ],
    },
    {
        path: "reports",
        children: [
            {
                element: <Navigate to="/dashboard/reports/logs" replace/>,
                index: true,
            },
            {path: "logs", element: <LogsList/>},
        ],
    },
    {
        path: "transaction",
        children: [
            {
                element: <Navigate to="/dashboard/transaction/list" replace/>,
                index: true,
            },
            {path: "list", element: <TransactionList/>},
        ],
    },
    {
        path: "media",
        children: [
            {
                element: <Navigate to="/dashboard/media/list" replace/>,
                index: true,
            },
            {path: "list", element: <MediaList/>},
            {path: "list/create", element: <MediaCreate/>},
            {path: "list/:id/edit", element: <MediaCreate/>},
        ],
    },
    {
        path: "campaign",
        children: [
            {
                element: <Navigate to="/dashboard/campaign/list" replace/>,
                index: true,
            },
            {path: "list", element: <CampaignList/>},
        ],
    },

    {
        path: "web-manager",
        children: [
            {path: "pageInfo", element: <PageInfoList/>},
            {path: "pageInfo/create", element: <PageInfoCreate/>},
            {path: "pageInfo/:id/:slug/edit", element: <PageInfoCreate/>},

            {path: "menus", element: <MenusList/>},
            {path: "menus/create", element: <MenusCreate/>},
            {path: "menus/:id/edit", element: <MenusCreate/>},

            {path: "subMenu", element: <SubMenuList/>},
            {path: "subMenu/create", element: <SubMenuCreate/>},
            {path: "subMenu/:id/edit", element: <SubMenuCreate/>},

            {path: "hiring", element: <HiringList/>},
            {path: "hiring/create", element: <HiringCreate/>},
            {path: "hiring/:id/edit", element: <HiringCreate/>},

            {path: "consult", element: <ConsultList/>},

            {path: "comments", element: <CommentsList/>},
            {path: "comments/create", element: <CommentsCreate/>},
            {path: "comments/:id/edit", element: <CommentsCreate/>},

            // there is an exception bcz they must be in the same group,but we don't have dual sub in our structure
            {path: "faqProfile", element: <FAQProfileList/>},
            {path: "faqProfile/create", element: <FAQProfileListCreate/>},
            {path: "faqProfile/:id/edit", element: <FAQProfileListCreate/>},
            {
                path: "faqProfile/:attrId/from-product",
                element: <FAQProfileListCreate/>,
            },

            {path: "faqProfile-category", element: <FAQProfileCategoryList/>},
            {
                path: "faqProfile-category/create",
                element: <FAQProfileCategoryCreate/>,
            },
            {
                path: "faqProfile-category/:id/edit",
                element: <FAQProfileCategoryCreate/>,
            },

            {path: "process", element: <ProcessList/>},
            {path: "process/create", element: <ProcessCreate/>},
            {path: "process/edit/:id", element: <ProcessCreate/>},
            {path: "process/steps/:id", element: <StepsList/>},

            {path: "landing", element: <LandingList/>},
            {path: "landing/create", element: <LandingCreate/>},
            {path: "landing/edit/:id", element: <LandingCreate/>},
        ],
    },

    {
        path: "products",
        children: [
            {
                element: <Navigate to="/dashboard/products/list" replace/>,
                index: true,
            },
            {path: "list", element: <StoreProducts/>},
            {path: "list/create", element: <StoreProductsCreate/>},
            {path: "list/:slug/:id/edit", element: <StoreProductsCreate/>},

            {path: "detail", element: <StoreProductsAttr/>},
            {path: "detail/create", element: <ProductsAttrCreate/>},
            {path: "detail/:id/edit", element: <ProductsAttrCreate/>},
            {
                path: "detail/:attrId/from-product",
                element: <ProductsAttrCreate/>,
            },

            {path: "category", element: <StoresCategoryList/>},
            {path: "category/create", element: <CategoryCreate/>},
            {path: "category/:id/edit", element: <CategoryCreate/>},

            {path: "faq", element: <FAQList/>},
            {path: "faq/create", element: <FAQListCreate/>},
            {path: "faq/:id/edit", element: <FAQListCreate/>},
            {path: "faq/:attrId/from-product", element: <FAQListCreate/>},

            {path: "faq/category", element: <FAQCategoryList/>},
            {path: "faq/category/create", element: <FAQCategoryCreate/>},
            {path: "faq/category/:id/edit", element: <FAQCategoryCreate/>},

            {path: "license", element: <LicenseList/>},
            {path: "license/create", element: <LicenseCreate/>},
            {path: "license/:id/edit", element: <LicenseCreate/>},

            {path: "productsWithLicense", element: <ProductWithLicenseList/>},
            {
                path: "productsWithLicense/create",
                element: <ProductWithLicenseCreate/>,
            },
            {
                path: "productsWithLicense/:id/edit",
                element: <ProductWithLicenseCreate/>,
            },
            {
                path: "productsWithLicense/:attrId/from-product",
                element: <ProductWithLicenseCreate/>,
            },
        ],
    },

    {
        path: "users",
        children: [
            {
                element: <Navigate to="/dashboard/users/list" replace/>,
                index: true,
            },
            {path: "list", element: <UserList/>},
            {path: "list/created", element: <UserCreated/>},
            {path: "list/:id/users/edit", element: <UserCreated/>},
            {path: "list/:id/edit", element: <UserCreate/>},
            {path: "list/:id/status", element: <UserStatus/>},
            {path: "list/:id/attributes", element: <UserInfoAttributes/>},

            {path: "referrals", element: <ReferralsList/>},

            {path: "modules", element: <ModulesList/>},
            {path: "modules/create", element: <ModulesCreate/>},
            {path: "modules/:id/edit", element: <ModulesCreate/>},

            {path: "roles", element: <RolesList/>},
            {path: "roles/create", element: <RolesCreate/>},
            {path: "roles/:id/edit", element: <RolesCreate/>},

            {path: "group", element: <GroupList/>},
            {path: "group/create", element: <GroupCreate/>},
            {path: "group/:id/edit", element: <GroupCreate/>},

            {path: "cart", element: <CartList/>},

            {path: "crm", element: <CrmList/>},
            {path: "crm/create", element: <CrmCreate/>},
            {path: "crm/:id/edit", element: <CrmCreate/>},

            {path: "crmContacts", element: <CrmContactsList/>},
            {path: "crmContacts/create", element: <CrmContactsCreate/>},
            {path: "crmContacts/:id/edit", element: <CrmContactsCreate/>},

            {path: "profiles", element: <ProfilesList/>},
            {path: "profiles/create", element: <ProfilesCreate/>},
            {path: "profiles/:id/edit", element: <ProfilesCreate/>},
        ],
    },

    {
        path: "profile",
        children: [
            {
                element: (
                    <Navigate to="/dashboard/profile/userAttributes" replace/>
                ),
                index: true,
            },
            {path: "userAttributes", element: <UserAttributesList/>},
            {path: "userAttributes/create", element: <UserAttributesCreate/>},
            {
                path: "userAttributes/:id/edit",
                element: <UserAttributesCreate/>,
            },

            {path: "userDoc", element: <UserDocList/>},
            {path: "userDoc/create", element: <UserDocListCreate/>},
            {path: "userDoc/:id/edit", element: <UserDocListCreate/>},

            {path: "news", element: <NewsList/>},
            {path: "news/create", element: <NewsListCreate/>},
            {path: "news/:id/edit", element: <NewsListCreate/>},

            {path: "newsCategory", element: <NewsCategoryList/>},
            {path: "newsCategory/create", element: <NewsCategoryCreate/>},
            {path: "newsCategory/:id/edit", element: <NewsCategoryCreate/>},

            {path: "categoryUpload", element: <CategoryUploadList/>},
            {
                path: "categoryUpload/create",
                element: <CategoryUploadListCreate/>,
            },
            {
                path: "categoryUpload/:id/edit",
                element: <CategoryUploadListCreate/>,
            },

            {path: "upload", element: <UploadList/>},
            {path: "upload/create", element: <UploadListCreate/>},
            {path: "upload/:id/edit", element: <UploadListCreate/>},
        ],
    },
]

export const staticRoutesFunc = () => {
    return [
        {path: "/", element: <Navigate to="/dashboard/app" replace/>},
        {
            path: "auth",
            children: [
                {
                    path: "login",
                    element: (
                        <GuestGuard>
                            <Login/>
                        </GuestGuard>
                    ),
                },
            ],
        },
        {
            path: "dashboard",
            element: (
                <AuthGuard>
                    <DashboardLayout/>
                </AuthGuard>
            ),
            children: [
                // {path: "app", element: <GeneralApp/>},
                // {path: "transaction", element: <TransactionList/>},
                ...dynamicRoutes
            ],
        },
        {
            path: "*",
            element: <LogoOnlyLayout/>,
            children: [
                {path: "500", element: <Page500/>},
                {path: "404", element: <NotFound/>},
                {path: "*", element: <Navigate to="/404" replace/>},
            ],
        },
        {path: "*", element: <Navigate to="/404" replace/>},
    ];
}*/

const routesConfig = [
    {path: "/", element: <Navigate to="/dashboard/app" replace/>},

    {
        path: "auth",
        children: [
            {
                path: "login",
                element: (
                    <GuestGuard>
                        <Login/>
                    </GuestGuard>
                ),
            },
        ],
    },

    // Dashboard Routes
    {
        path: "dashboard",
        element: (
            <AuthGuard>
                <DashboardLayout/>
            </AuthGuard>
        ),
        children: [
            {path: "app", element: <GeneralApp/>},
            {
                path: "blog",
                children: [
                    {
                        element: <Navigate to="/dashboard/blog/list" replace/>,
                        index: true,
                    },
                    {path: "list", element: <BlogList/>},
                    {path: "list/create", element: <BlogListCreate/>},
                    {path: "list/:id/edit", element: <BlogListCreate/>},

                    {path: "category", element: <BlogCategoryList/>},
                    {path: "category/create", element: <BlogCategoryCreate/>},
                    {path: "category/:id/edit", element: <BlogCategoryCreate/>},

                    {path: "tags", element: <BlogTagList/>},
                    {path: "tags/create", element: <BlogTagCreate/>},
                    {path: "tags/:id/edit", element: <BlogTagCreate/>},
                ],
            },
            {
                path: "reports",
                children: [
                    {
                        element: <Navigate to="/dashboard/reports/logs" replace/>,
                        index: true,
                    },
                    {path: "logs", element: <LogsList/>},
                ],
            },
            {
                path: "transaction",
                children: [
                    {
                        element: <Navigate to="/dashboard/transaction/list" replace/>,
                        index: true,
                    },
                    {path: "list", element: <TransactionList/>},
                ],
            },
            {
                path: "media",
                children: [
                    {
                        element: <Navigate to="/dashboard/media/list" replace/>,
                        index: true,
                    },
                    {path: "list", element: <MediaList/>},
                    {path: "list/create", element: <MediaCreate/>},
                    {path: "list/:id/edit", element: <MediaCreate/>},
                ],
            },
            {
                path: "campaign",
                children: [
                    {
                        element: <Navigate to="/dashboard/campaign/list" replace/>,
                        index: true,
                    },
                    {path: "list", element: <CampaignList/>},
                ],
            },

            {
                path: "web-manager",
                children: [
                    {path: "pageInfo", element: <PageInfoList/>},
                    {path: "pageInfo/create", element: <PageInfoCreate/>},
                    {path: "pageInfo/:id/:slug/edit", element: <PageInfoCreate/>},

                    {path: "menus", element: <MenusList/>},
                    {path: "menus/create", element: <MenusCreate/>},
                    {path: "menus/:id/edit", element: <MenusCreate/>},

                    {path: "subMenu", element: <SubMenuList/>},
                    {path: "subMenu/create", element: <SubMenuCreate/>},
                    {path: "subMenu/:id/edit", element: <SubMenuCreate/>},

                    {path: "hiring", element: <HiringList/>},
                    {path: "hiring/create", element: <HiringCreate/>},
                    {path: "hiring/:id/edit", element: <HiringCreate/>},

                    {path: "consult", element: <ConsultList/>},

                    {path: "comments", element: <CommentsList/>},
                    {path: "comments/create", element: <CommentsCreate/>},
                    {path: "comments/:id/edit", element: <CommentsCreate/>},

                    // there is an exception bcz they must be in the same group,but we don't have dual sub in our structure
                    {path: "faqProfile", element: <FAQProfileList/>},
                    {path: "faqProfile/create", element: <FAQProfileListCreate/>},
                    {path: "faqProfile/:id/edit", element: <FAQProfileListCreate/>},
                    {
                        path: "faqProfile/:attrId/from-product",
                        element: <FAQProfileListCreate/>,
                    },

                    {path: "faqProfile-category", element: <FAQProfileCategoryList/>},
                    {
                        path: "faqProfile-category/create",
                        element: <FAQProfileCategoryCreate/>,
                    },
                    {
                        path: "faqProfile-category/:id/edit",
                        element: <FAQProfileCategoryCreate/>,
                    },

                    {path: "process", element: <ProcessList/>},
                    {path: "process/create", element: <ProcessCreate/>},
                    {path: "process/edit/:id", element: <ProcessCreate/>},
                    {path: "process/steps/:id", element: <StepsList/>},

                    {path: "landing", element: <LandingList/>},
                    {path: "landing/create", element: <LandingCreate/>},
                    {path: "landing/edit/:id", element: <LandingCreate/>},
                ],
            },

            {
                path: "products",
                children: [
                    {
                        element: <Navigate to="/dashboard/products/list" replace/>,
                        index: true,
                    },
                    {path: "list", element: <StoreProducts/>},
                    {path: "list/create", element: <StoreProductsCreate/>},
                    {path: "list/:slug/:id/edit", element: <StoreProductsCreate/>},

                    {path: "detail", element: <StoreProductsAttr/>},
                    {path: "detail/create", element: <ProductsAttrCreate/>},
                    {path: "detail/:id/edit", element: <ProductsAttrCreate/>},
                    {
                        path: "detail/:attrId/from-product",
                        element: <ProductsAttrCreate/>,
                    },

                    {path: "category", element: <StoresCategoryList/>},
                    {path: "category/create", element: <CategoryCreate/>},
                    {path: "category/:id/edit", element: <CategoryCreate/>},

                    {path: "faq", element: <FAQList/>},
                    {path: "faq/create", element: <FAQListCreate/>},
                    {path: "faq/:id/edit", element: <FAQListCreate/>},
                    {path: "faq/:attrId/from-product", element: <FAQListCreate/>},

                    {path: "faq/category", element: <FAQCategoryList/>},
                    {path: "faq/category/create", element: <FAQCategoryCreate/>},
                    {path: "faq/category/:id/edit", element: <FAQCategoryCreate/>},

                    {path: "license", element: <LicenseList/>},
                    {path: "license/create", element: <LicenseCreate/>},
                    {path: "license/:id/edit", element: <LicenseCreate/>},

                    {path: "productsWithLicense", element: <ProductWithLicenseList/>},
                    {
                        path: "productsWithLicense/create",
                        element: <ProductWithLicenseCreate/>,
                    },
                    {
                        path: "productsWithLicense/:id/edit",
                        element: <ProductWithLicenseCreate/>,
                    },
                    {
                        path: "productsWithLicense/:attrId/from-product",
                        element: <ProductWithLicenseCreate/>,
                    },
                ],
            },

            {
                path: "users",
                children: [
                    {
                        element: <Navigate to="/dashboard/users/list" replace/>,
                        index: true,
                    },
                    {path: "list", element: <UserList/>},
                    {path: "list/created", element: <UserCreated/>},
                    {path: "list/:id/users/edit", element: <UserCreated/>},
                    {path: "list/:id/edit", element: <UserCreate/>},
                    {path: "list/:id/status", element: <UserStatus/>},
                    {path: "list/:id/attributes", element: <UserInfoAttributes/>},

                    {path: "referrals", element: <ReferralsList/>},

                    {path: "modules", element: <ModulesList/>},
                    {path: "modules/create", element: <ModulesCreate/>},
                    {path: "modules/:id/edit", element: <ModulesCreate/>},

                    {path: "roles", element: <RolesList/>},
                    {path: "roles/create", element: <RolesCreate/>},
                    {path: "roles/:id/edit", element: <RolesCreate/>},

                    {path: "group", element: <GroupList/>},
                    {path: "group/create", element: <GroupCreate/>},
                    {path: "group/:id/edit", element: <GroupCreate/>},

                    {path: "cart", element: <CartList/>},

                    {path: "crm", element: <CrmList/>},
                    {path: "crm/create", element: <CrmCreate/>},
                    {path: "crm/:id/edit", element: <CrmCreate/>},

                    {path: "crmContacts", element: <CrmContactsList/>},
                    {path: "crmContacts/create", element: <CrmContactsCreate/>},
                    {path: "crmContacts/:id/edit", element: <CrmContactsCreate/>},

                    {path: "profiles", element: <ProfilesList/>},
                    {path: "profiles/create", element: <ProfilesCreate/>},
                    {path: "profiles/:id/edit", element: <ProfilesCreate/>},
                ],
            },

            {
                path: "profile",
                children: [
                    {
                        element: (
                            <Navigate to="/dashboard/profile/userAttributes" replace/>
                        ),
                        index: true,
                    },
                    {path: "userAttributes", element: <UserAttributesList/>},
                    {path: "userAttributes/create", element: <UserAttributesCreate/>},
                    {
                        path: "userAttributes/:id/edit",
                        element: <UserAttributesCreate/>,
                    },

                    {path: "userDoc", element: <UserDocList/>},
                    {path: "userDoc/create", element: <UserDocListCreate/>},
                    {path: "userDoc/:id/edit", element: <UserDocListCreate/>},

                    {path: "news", element: <NewsList/>},
                    {path: "news/create", element: <NewsListCreate/>},
                    {path: "news/:id/edit", element: <NewsListCreate/>},

                    {path: "newsCategory", element: <NewsCategoryList/>},
                    {path: "newsCategory/create", element: <NewsCategoryCreate/>},
                    {path: "newsCategory/:id/edit", element: <NewsCategoryCreate/>},

                    {path: "categoryUpload", element: <CategoryUploadList/>},
                    {
                        path: "categoryUpload/create",
                        element: <CategoryUploadListCreate/>,
                    },
                    {
                        path: "categoryUpload/:id/edit",
                        element: <CategoryUploadListCreate/>,
                    },

                    {path: "upload", element: <UploadList/>},
                    {path: "upload/create", element: <UploadListCreate/>},
                    {path: "upload/:id/edit", element: <UploadListCreate/>},
                ],
            },
        ],
    },
    // Main Routes
    {
        path: "*",
        element: <LogoOnlyLayout/>,
        children: [
            {path: "500", element: <Page500/>},
            {path: "404", element: <NotFound/>},
            {path: "*", element: <Navigate to="/404" replace/>},
        ],
    },
    {path: "*", element: <Navigate to="/404" replace/>},
];
export default routesConfig
// store Routes
/*
{
  path: "/store",
      element: (
    <AuthGuard>
      <DashboardLayout />
    </AuthGuard>
),
    // these are the unresolved sections that remain, should ask !!!
    children: [
  {
    path: "newsTag",
    children: [
      {
        element: <Navigate to="/news/newsTag/list" replace />,
        index: true,
      },
      { path: "list", element: <NewsTagList /> },
      { path: "list/create", element: <NewsTagCreate /> },
      { path: "list/:id/edit", element: <NewsTagCreate /> },
    ],
  },

  {
    path: "permission",
    children: [
      {
        element: <Navigate to="/store/permission/list" replace />,
        index: true,
      },
      { path: "list", element: <PermissionList /> },
      { path: "list/create", element: <PermissionCreate /> },
      { path: "list/:id/edit", element: <PermissionCreate /> },
    ],
  },
  {
    path: "questionSurvey",
    children: [
      {
        element: <Navigate to="/store/questionSurvey/list" replace />,
        index: true,
      },
      { path: "list", element: <QuestionSurveyList /> },
      { path: "list/create", element: <QuestionSurveyListCreate /> },
      { path: "list/:id/edit", element: <QuestionSurveyListCreate /> },
    ],
  },
  {
    path: "categoryUpload",
    children: [
      {
        element: <Navigate to="/store/categoryUpload/list" replace />,
        index: true,
      },
      { path: "list", element: <CategoryUploadList /> },
      { path: "list/create", element: <CategoryUploadListCreate /> },
      { path: "list/:id/edit", element: <CategoryUploadListCreate /> },
    ],
  },
  {
    path: "upload",
    children: [
      {
        element: <Navigate to="/store/upload/list" replace />,
        index: true,
      },
      { path: "list", element: <UploadList /> },
      { path: "list/create", element: <UploadListCreate /> },
      { path: "list/:id/edit", element: <UploadListCreate /> },
    ],
  },
  {
    path: "ticket",
    children: [
      {
        element: <Navigate to="/store/ticket/list" replace />,
        index: true,
      },
      { path: "list", element: <TicketsList /> },
      { path: "list/:id/edit", element: <TicketAnswerCreate /> },
      // { path: "list/create", element: <QuestionSurveyListCreate /> },
    ],
  },
  {
    path: "ticketCategory",
    children: [
      {
        element: <Navigate to="/store/ticketCategory/list" replace />,
        index: true,
      },
      { path: "list", element: <TicketCategoryList /> },
      { path: "list/:id/edit", element: <TicketCategoryListCreate /> },
      { path: "list/create", element: <TicketCategoryListCreate /> },
    ],
  },
  {
    path: "topic",
    children: [
      {
        element: <Navigate to="/store/topic/list" replace />,
        index: true,
      },
      // { path: "list", element: <TicketCategoryList /> },
      { path: "list/:id/edit", element: <TopicListCreate /> },
      { path: "list/:attrId/from-product", element: <TopicListCreate /> },
    ],
  },

  {
    path: "lms",
    children: [
      {
        element: <Navigate to="/store/lms/list" replace />,
        index: true,
      },
      { path: "list", element: <LmsList /> },
      { path: "license", element: <LicenselmsList /> },
      { path: "license/create", element: <LicenselmsCreate /> },
      { path: "list/create", element: <LmsListCreate /> },
      { path: "list/:id/edit", element: <LmsListCreate /> },
      { path: "list/:id/QuestionCreate", element: <QusListCreate /> },
      { path: "list/:id/QuestionEdit", element: <QusListCreate /> },
    ],
  },
  {
    path: "IELTS",
    children: [
      {
        element: <Navigate to="/store/IELTS/list" replace />,
        index: true,
      },
      { path: "list", element: <IELTSList /> },
      { path: "list/create", element: <IELTSListCreate /> },
      { path: "list/:id/edit", element: <IELTSListCreate /> },
    ],
  },
],
},*/
