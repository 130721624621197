import React, {createContext, useContext} from 'react';
import useAuth from "hooks/useAuth";

// Create the PermissionsContext
const PermissionsContext = createContext();

// Provider Component
export function PermissionsProvider({children}) {
    const {user} = useAuth(); // Pull the user object from your auth system

    // Function to check permissions based on parentName and childName
    const checkPermissions = (parentName, childName) => {
        if (!user || !user.modules) return null; // Early return if user or modules are not available
        const permissionsToCheck = ['index', 'show', 'create', 'edit', 'destroy'];

        const findPermissions = (dataArr, parentName, childName) => {
            // Your permission checking logic here (reuse the function you shared)
            const parentItem = dataArr.find(item => item.title === parentName);
            if (!parentItem) return permissionsToCheck.reduce((acc, perm) => ({...acc, [perm]: false}), {});

            let itemToCheck = parentItem;
            if (childName && parentItem.children) {
                const childItem = parentItem.children.find(child => child.title === childName);
                if (childItem) itemToCheck = childItem;
            }

            return permissionsToCheck.reduce((acc, permission) => {
                acc[permission] = itemToCheck.permissions?.some(p => p.slug === permission) || false;
                return acc;
            }, {});
        };

        return findPermissions(user.modules, parentName, childName);
    };

    return (
        <PermissionsContext.Provider value={{checkPermissions}}>
            {children}
        </PermissionsContext.Provider>
    );
}

// Custom hook to use permissions in components
export const usePermissions = () => {
    return useContext(PermissionsContext);
};
